body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.navbar-brand {
  margin-left: 20px;
}
.App-header {
}

.header {
  width: 100%;
  position: fixed;
  z-index: 10;
  background: inherit;
}
.container {
  width: 80%;
  height: 80%;
  overflow-y: "scroll";
  position: relative;
  top: 68px;
  width: 100%;
  height: 80%;
  overflow: auto;
}

/* .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #222222;
  padding-top: 8px;
  color: white;

  z-index: 5;
  overflow: auto;
} */

.code-edit {
  height: 600px;
  overflow-y: scroll;
  text-align: left;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.grid-item {
  display: grid;
  margin: 10px;
  background-color: aquamarine;
}
.grid-item-title {
  margin-bottom: 10px;
}

.grid-item-btn {
  margin: 5px;
  padding: 2px;
}

.react-syntax-highlighter-line-number {
  line-height: 1.72;
}

.countdown-page {
  min-height: 500px;
}

.slide-text {
  /* font-size: 200px; */
  height: 300px;
  width: 300px;
  margin: auto;
}
.toggle-btn {
  margin-top: 10px;
}

.tip-items {
  margin: 10px;
}
.tip-items:nth-child(odd) {
  background: rgb(136, 136, 210);
}

.tip-items:nth-child(even) {
  background: rgb(177, 127, 127);
}
.profile-picture {
  min-width: 300px;
  min-height: 400px;
  background-color: grey;
  margin: auto;
  display: inline-grid;
}
.profile-img {
}

.social-media-list-item {
  display: flex;
  justify-content: center;
}
.social-media-list-item-cell {
  margin: 0 20px;
}
.circle {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}
@media only screen and (max-width: 600px) {
  .code-edit {
    height: 300px;
    overflow-y: scroll;
    text-align: left;
    margin-top: 30px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
  }

  .countdown-page {
    margin-top: 50px;
  }

  .tips-container {
    margin-top: 50px;
    font-size: 30px;
  }
  .body-container {
    margin-top: 50px;
  }
}
